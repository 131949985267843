import photo1 from './04_01.jpg';
import photo2 from './04_02.jpg';
import photo3 from './04_03.jpg';
import photo4 from './04_04.jpg';
import photo5 from './04_05.jpg';
import photo6 from './04_06.jpg';
import photo7 from './04_07.jpg';
import photo8 from './04_08.jpg';
import photo9 from './04_09.jpg';
import photo10 from './04_10.jpg';
import photo11 from './04_11.jpg';
import photo12 from './04_12.jpg';

const images04: string[] = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo12,
];

export default images04;
